import React from 'react';
import loadable from '@loadable/component';

import { theme } from '../styles/theme';
import { ThemeProvider } from '../styles/ThemeProvider';
const AboutComponent = loadable(() => import('../components/about/about-main'))
const WholePageOpacity = loadable(() => import('../animations/whole-page-opacity'))
const Layout = loadable(() => import('../components/Layout'))
const Head = loadable(() => import('../components/Head'))

const About = () => {

  return (
    <ThemeProvider theme={theme}>
      <Layout>
              <Head title="About" />
              <WholePageOpacity><AboutComponent /></WholePageOpacity>                           
          </Layout>

    </ThemeProvider>
  );
};

export default About;
